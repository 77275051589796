<template lang="pug">
.login-page-container
  .login
    h1.login-title {{ globalDistributor.name }}
    form.form(@submit.prevent='submit')
      div.mb-4(v-if='newPasswordMode')
        h2 Por favor elija una nueva contraseña para su cuenta
      b-field.mb-5(v-if='!newPasswordMode' label='Correo' label-position='on-border')
        b-input#email(type='text' placeholder='Correo o Nombre de usuario' v-model='user.email' autofocus required)
      b-field.mb-5( :label='newPasswordMode ? "Nueva contraseña" : "Contraseña"' label-position='on-border')
        b-input#password(type='password' placeholder='Clave' v-model='user.password' autofocus :required='newPasswordMode')
      .form-button-container
        b-button(native-type='submit' type='is-primary' :loading='isLoading') {{ newPasswordMode ? 'Crear contraseña' : 'Iniciar sesión' }}
</template>

<script>
import { userPermissions } from '@/constants/enums'
import { mapActions } from 'vuex'
import { map, inPoint } from '@/router/routes'
import toastService from '@/services/toast.service'
import authService from '@/services/auth.service'

export default {
  data () {
    return {
      isLoading: false,
      newPasswordMode: false,
      user: {
        id: 0,
        email: '',
        password: ''
      }
    }
  },
  computed: {
    globalDistributor () {
      return this.$store.state.global.distributor || {}
    }
  },
  methods: {
    ...mapActions({
      login: 'auth/login'
    }),
    async submit () {
      this.isLoading = true
      if (this.newPasswordMode) {
        const result = await authService.assignPassword(this.user)
        if (result) {
          toastService.show('Clave asignada, por favor inicie sesión')
          this.newPasswordMode = false
          this.user.id = 0
        }
      } else {
        const result = await this.login(this.user)
        if (result) {
          if (result.needPassword) {
            this.newPasswordMode = true
            this.user.id = result.id
          } else if (result.permissionsArray.includes(userPermissions.INPOINT_APP)) {
            this.$router.push({ name: inPoint.name })
          } else {
            this.$router.push({ name: map.name })
          }
        }
      }
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables/sizes';

.login-page-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.login {
  background: white;
  width: 100%;
  max-width: 450px;
  margin: 20px auto;
  padding: 20px;
  border-radius: $border-radius;
  box-shadow: 1px 1px 50px #000;
}

.login-title {
  margin-bottom: 20px;
  font-size: 1.5em;
}

.form {
  width: 100%;
}
</style>
